import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { deviceUserColumn } from '../../columns/device-user.column';
import { ButtonLink } from '../../components/ButtonLink';
import { DeviceUserFilter } from '../../components/filters/DeviceUserFilter';
import { getRoute } from '../../config/routes.config';
import { DeviceUserFilterContext } from '../../contexts/DeviceUserFilterContext';
import { useDeviceUserIndexSceneLazyQuery } from '../../generated/graphql';
import { AppIndexScene } from '../../releox-engine/react/releox-app-index-scene/AppIndexScene';

export const DeviceUserIndexScene = (): JSX.Element => {
  const { t } = useTranslation('DeviceUserIndexScene');
  const query = useDeviceUserIndexSceneLazyQuery();
  const navigate = useNavigate();
  const [filter] = useContext(DeviceUserFilterContext);

  const Tool = (
    <ButtonLink className="float-right" to={getRoute('DEVICE_USER_CREATE')}>
      {t('create')}
    </ButtonLink>
  );

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-x-3 gap-y-3">
      <div>
        <DeviceUserFilter />
      </div>
      <div className="col-span-3">
        <AppIndexScene
          tNS="DeviceUserIndexScene"
          lazyQuery={query}
          trOnClick={(data) =>
            navigate(getRoute('DEVICE_USER_EDIT', { id: data.id, page: 'info' }))
          }
          columns={[
            deviceUserColumn.id,
            deviceUserColumn.name,
            deviceUserColumn.tag,
            deviceUserColumn.accountType,
          ]}
          dataPath="deviceUsers.list"
          totalCountPath="deviceUsers.totalCount"
          Tool={Tool}
          where={filter}
        />
      </div>
    </div>
  );
};
