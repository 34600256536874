import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { departmentColumn } from '../../columns/department.column';
import { ButtonLink } from '../../components/ButtonLink';
import { getRoute } from '../../config/routes.config';
import { useDepartmentIndexSceneLazyQuery } from '../../generated/graphql';
import { AppIndexScene } from '../../releox-engine/react/releox-app-index-scene/AppIndexScene';

export const DepartmentIndexScene = (): JSX.Element => {
  const { t } = useTranslation('DepartmentIndexScene');
  const query = useDepartmentIndexSceneLazyQuery();
  const navigate = useNavigate();

  const Tool = (
    <ButtonLink className="float-right" to={getRoute('DEPARTMENT_CREATE')}>
      {t('create')}
    </ButtonLink>
  );

  return (
    <AppIndexScene
      tNS={'DepartmentIndexScene'}
      lazyQuery={query}
      trOnClick={(data) => navigate(getRoute('DEPARTMENT_EDIT', { id: data.id }))}
      columns={[departmentColumn.id, departmentColumn.name]}
      dataPath="departments.list"
      totalCountPath="departments.totalCount"
      Tool={Tool}
    />
  );
};
