import { useCallback, useEffect, useMemo, useState } from 'react';
import { getPagination, PaginationItem } from './get-pagination';

interface Props {
  total: number;
  perPage: number;
  currentPageIndex?: number;
  onCurrentPageIndexChange?: (page: number) => void;
}

export type ReleoxPagination = {
  currentPage: number;
  currentPageIndex: number;
  total: number;
  perPage: number;
  skip: number;
  pageCount: number;
  pagination: PaginationItem[];
  nextPage(): void;
  previousPage(): void;
};

export const useReleoxPagination = (options: Props): ReleoxPagination => {
  const {
    currentPageIndex: initCurrentPageIndex,
    onCurrentPageIndexChange,
    total,
    perPage,
  } = options;
  const [currentPageIndex, setCurrentPageIndex] = useState(() => initCurrentPageIndex || 0);
  const pageCount = useMemo(() => Math.round(total / perPage), [total, perPage]);

  useEffect(() => {
    if (typeof initCurrentPageIndex === 'number') setCurrentPageIndex(initCurrentPageIndex);
  }, [initCurrentPageIndex]);

  const setPage = useCallback(
    (pageIndex: number) => {
      // Call callback
      if (onCurrentPageIndexChange) onCurrentPageIndexChange(pageIndex);
      // Setup current page index
      setCurrentPageIndex(pageIndex);
    },
    [onCurrentPageIndexChange]
  );

  const [pagination, setPagination] = useState(
    getPagination({
      count: total,
      currentPageIndex,
      perPage: perPage,
      setCurrentPage: setPage,
    })
  );

  useEffect(() => {
    const localPag = getPagination({
      count: total,
      currentPageIndex,
      perPage: perPage,
      setCurrentPage: setPage,
    });

    setPagination(localPag);
  }, [setPage, total, perPage, currentPageIndex]);

  const previousPage = useCallback(() => {
    setCurrentPageIndex(currentPageIndex - 1);
  }, [currentPageIndex]);

  const nextPage = useCallback(() => {
    setCurrentPageIndex(currentPageIndex + 1);
  }, [currentPageIndex]);

  return {
    currentPage: currentPageIndex + 1,
    currentPageIndex,
    total,
    pageCount,
    perPage,
    skip: perPage * currentPageIndex,
    nextPage,
    previousPage,
    pagination,
  };
};
