/* eslint-disable @typescript-eslint/no-explicit-any */

interface Options {
  count: number;
  // Index start from zero
  currentPageIndex: number;
  perPage: number;
  setCurrentPage(i: number): void;
}

export type PaginationItemType = 'page' | 'last' | 'first';

export interface PaginationItem {
  type: PaginationItemType;
  text: string;
  page: number;
  isCurrent: boolean;
  isFirst: boolean;
  isLast: boolean;
  onClick(): any;
}

export const getPagination = (options: Options): PaginationItem[] => {
  const { count, currentPageIndex, perPage, setCurrentPage } = options;

  const paginationNumbers: number[] = [];

  for (let i = 0; i < Math.ceil(count / perPage); i += 1) {
    paginationNumbers.push(i);
  }

  const lastIndex = paginationNumbers.length - 1;

  const paginationItems: PaginationItem[] = paginationNumbers
    .filter((i) => i < currentPageIndex + 3 && i > currentPageIndex - 3)
    .map((i) => ({
      isFirst: false,
      isLast: false,
      type: 'page',
      text: `${i + 1}`,
      isCurrent: currentPageIndex === i,
      page: i,
      onClick: () => setCurrentPage(i),
    }));

  if (currentPageIndex - 3 > -1) {
    paginationItems.unshift({
      isFirst: false,
      isLast: false,
      type: 'first',
      text: 'First',
      isCurrent: false,
      page: 1,
      onClick: () => setCurrentPage(0),
    });
  }

  if (currentPageIndex + 2 < paginationNumbers[lastIndex]) {
    paginationItems.push({
      isFirst: false,
      isLast: false,
      type: 'last',
      text: 'Last',
      isCurrent: false,
      page: paginationNumbers[lastIndex],
      onClick: () => setCurrentPage(paginationNumbers[lastIndex]),
    });
  }

  if (paginationItems.length) {
    paginationItems[0].isFirst = true;
    paginationItems[paginationItems.length - 1].isLast = true;
  }

  return paginationItems;
};
