import _ from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getRoute } from '../../config/routes.config';
import { deviceUserForm, DeviceUserFormBody } from '../../forms/device-user.form';
import { useCreateDeviceUserMutation } from '../../generated/graphql';
import { useOnComplete } from '../../hooks/use-on-complete';
import { AppFormScene } from '../../releox-engine/react/app-form-scene/AppFormScene';

export const DeviceUserCreateScene = (): JSX.Element => {
  const { t } = useTranslation('DeviceUserCreateScene');
  const onComplete = useOnComplete({ route: 'DEVICE_USER_INDEX' });
  const [createDeviceUser, { loading }] = useCreateDeviceUserMutation({
    onCompleted: onComplete,
  });

  const handleSubmit = useCallback(
    (body: DeviceUserFormBody) => {
      createDeviceUser({
        variables: {
          body: _.omit(body, 'pinConfirm'),
        },
      });
    },
    [createDeviceUser]
  );

  return (
    <AppFormScene
      type="create"
      title={t('title')}
      form={deviceUserForm}
      isSubmitLoading={loading}
      backLink={getRoute('DEVICE_USER_INDEX')}
      onSubmit={handleSubmit}
    />
  );
};
