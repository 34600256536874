import { createContext, useState } from 'react';
import { ChildrenProp } from '../types/ChildrenProp';
import { FilterContext } from '../types/FilterContext';

export type DeviceUserFilterContextData = {
  firstName?: string;
  lastName?: string;
  accountType?: string;
  tag?: string;
};

export const DeviceUserFilterContext = createContext<FilterContext<DeviceUserFilterContextData>>([
  {},
  () => ({}),
]);

export const DeviceUserFilterContextProvider = ({ children }: ChildrenProp) => {
  const state = useState<DeviceUserFilterContextData>({});

  return (
    <DeviceUserFilterContext.Provider value={state}>{children}</DeviceUserFilterContext.Provider>
  );
};
