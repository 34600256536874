import { GenericTableHeader, GenericTableHeaderSortIcon } from './GenericTableHeader';
import { GenericTableRow } from './GenericTableRow';
import { TableColumn } from './TableColumn';

export type GenericTableProps = {
  columns: TableColumn[];
  onSortClick?(column: TableColumn): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any[];
};

type Classes = {
  thClassNames?: string;
  thActiveClassNames?: string;
  tdClassNames?: string;
  trClassNames?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trOnClick?: (data: any) => any;
  tableClassNames?: string;
  tHeadClassNames?: string;
  tBodyClassNames?: string;
  sortIconClassNames?: GenericTableHeaderSortIcon;
};

export const GenericTable = (props: GenericTableProps & Classes) => {
  const {
    data,
    onSortClick,
    columns,
    thClassNames,
    thActiveClassNames,
    tdClassNames,
    trClassNames,
    tableClassNames,
    tHeadClassNames,
    tBodyClassNames,
    sortIconClassNames,
  } = props;
  return (
    <table className={tableClassNames}>
      <thead className={tHeadClassNames}>
        <tr>
          {columns.map((column) => (
            <GenericTableHeader
              key={`${column.field}-${column.text}`}
              thClassNames={thClassNames}
              thActiveClassNames={thActiveClassNames}
              sortIconClassNames={sortIconClassNames}
              column={column}
              onSortClick={onSortClick}
            />
          ))}
        </tr>
      </thead>
      <tbody className={tBodyClassNames}>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {(data || []).map((d: any) => (
          <GenericTableRow
            trOnClick={props.trOnClick}
            trClassNames={trClassNames}
            tdClassNames={tdClassNames}
            key={d.id}
            data={d}
            columns={columns}
          />
        ))}
      </tbody>
    </table>
  );
};
