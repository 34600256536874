import * as Yup from 'yup';
import { FormInput } from '../releox-engine/form/FormInput';

const accountType: FormInput = {
  type: 'select',
  initValue: '',
  validation: Yup.string(),
  props: {
    name: 'accountType',
    label: 'DeviceUser:accountType',
    options: [
      {
        value: '',
        label: 'DeviceUser:chooseAccountType',
      },
      {
        value: 'basic',
        label: 'DeviceUser:basic',
      },
      {
        value: 'distributor',
        label: 'DeviceUser:distributor',
      },
    ],
  },
};

export const deviceUserFilterInput = {
  accountType,
};
