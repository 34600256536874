import { SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/outline';
import { CardTitle } from '../../../components/CardTitle';
import { EmptyTableElement } from '../../../components/EmptyTableElement';
import { LoadingTableElement } from '../../../components/LoadingTableElement';
import { TableLayout } from '../../../components/TableLayout';
import { UseIndexOptions } from '../../hooks/use-index/use-index';
import { AppIndexSceneEmptyElementProps } from './AppIndexSceneEmptyElementProps';
import { AppIndexSceneLoadingElementProps } from './AppIndexSceneLoadingElementProps';

export type AppIndexSceneTableClasses = {
  thActiveClassNames?: string;
  tableClassNames?: string;
  tHeadClassNames?: string;
  thClassNames?: string;
  tdClassNames?: string;
  trClassNames?: string;
};

type Config = {
  perPage: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Layout: (props: any) => JSX.Element;
  EmptyTableElement: (props: AppIndexSceneEmptyElementProps) => JSX.Element;
  LoadingElement: (props: AppIndexSceneLoadingElementProps) => JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Title: (props: any) => JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SortAscendingIcon: (props: any) => JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SortDescendingIcon: (props: any) => JSX.Element;
  defaultOrderBy: UseIndexOptions['defaultOrderBy'];
  defaultClasses?: AppIndexSceneTableClasses;
};

export const appIndexSceneConfig: Config = {
  perPage: 30,
  defaultOrderBy: { createdAt: 'desc' },
  Layout: TableLayout,
  Title: CardTitle,
  SortAscendingIcon: SortAscendingIcon,
  SortDescendingIcon: SortDescendingIcon,
  EmptyTableElement: EmptyTableElement,
  LoadingElement: LoadingTableElement,
  defaultClasses: {
    thActiveClassNames: 'text-secondary',
    tableClassNames:
      'bg-white min-w-full divide-y divide-gray-200 overflow-hidden border-b border-gray-200 rounded-lg',
    tHeadClassNames: 'bg-gray-50',
    thClassNames:
      'px-6 py-3 text-left text-xs font-medium text-gray-color uppercase tracking-wider',
    tdClassNames: `px-6 py-4 text-sm td-max-width text-ellipsis overflow-hidden`,
    trClassNames: 'even:bg-gray-50',
  },
};
