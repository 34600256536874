import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { TableColumn } from '../../react/generic-table/TableColumn';
import { useReleoxPagination } from '../pagination/use-releox-pagination';
import { useObjectChangeWatcher } from '../use-object-change-watcher/use-object-change-watcher';
import { useIndexDefaultAdapter, UseIndexDefaultAdapter } from './use-index-default-adapter';

export type OrderBySort = 'asc' | 'desc';

export type OrderBy = { [key: string]: OrderBySort };

export type UseIndexOptions = {
  // Total count of result in database
  totalCount?: number;

  // How many results want to seen in one page
  perPage: number;

  // Current page index. Can be used for saving search state purpose.
  currentPageIndex?: number;

  // Called when current page index is changed. Can be used for saving search state purpose.
  onCurrentPageIndexChange?: (page: number) => void;

  // Default order by
  defaultOrderBy: OrderBy;

  /* eslint-disable @typescript-eslint/no-explicit-any */

  // Table's where query used as argument of fetch
  where?: any;

  // Function that is called to fetch data
  fetch(options: any): void;

  // Function that is used to remap data from AdapterParam to custom fetchOptions
  adapter?: UseIndexDefaultAdapter;
  /* eslint-enable @typescript-eslint/no-explicit-any */
};

export const useIndex = (options: UseIndexOptions) => {
  const {
    fetch,
    totalCount,
    perPage,
    defaultOrderBy,
    adapter,
    where: defaultWhere,
    currentPageIndex,
    onCurrentPageIndexChange,
  } = options;

  const [orderBy, setOrderBy] = useState<UseIndexOptions['defaultOrderBy']>(defaultOrderBy);
  const [where, setWhere] = useState<UseIndexOptions['defaultOrderBy']>(defaultWhere);

  const pagination = useReleoxPagination({
    total: totalCount || 0,
    perPage,
    currentPageIndex,
    onCurrentPageIndexChange,
  });

  // Call setWhere when defaultWhere (props.where) is changed
  useObjectChangeWatcher(defaultWhere, setWhere);

  // Fetch data
  useEffect(() => {
    // Get adapter AKA. function that remap query parameter
    const localAdapter = adapter || useIndexDefaultAdapter;

    // Call adapter
    const queryArgs = localAdapter({
      orderBy,
      skip: pagination.skip,
      take: perPage,
      where,
    });

    // Fetch
    fetch(queryArgs);
  }, [pagination.skip, orderBy, fetch, adapter, perPage, where]);

  // Change orderBy state
  const handleSetOrderBy = useCallback(
    (column: TableColumn) => {
      // Default orderby
      let order: OrderBySort = 'asc';

      // Get current orderBy key from state
      const currentField = _.chain(orderBy).keys().first().value();

      // Get current orderBy sort order from state
      const currentOrder = _.chain(orderBy).values().first().value();

      // Check if current field is same than previous sort and order is ASC and reverse order
      if (currentField === column.field && currentOrder === 'asc') {
        order = 'desc';
      }

      // Update orderBy state
      setOrderBy({ [column.field]: order });
    },
    [setOrderBy, orderBy]
  );

  return {
    pagination,
    orderBy,
    setOrderBy: handleSetOrderBy,
  };
};
