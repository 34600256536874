import moment from 'moment';
import { LogMessage } from '../components/LogMessage';
import { dateFormat } from '../config/date-format';
import { EventIndexSceneQuery } from '../generated/graphql';
import { TableColumn } from '../releox-engine/react/generic-table/TableColumn';

const id: TableColumn = {
  field: 'id',
  text: 'Event:id',
};

const log: TableColumn = {
  field: 'userName',
  text: 'Event:log',
  formatter: (event: EventIndexSceneQuery['events']['list'][0]) => {
    return <LogMessage event={event} />;
  },
};

const deviceTime: TableColumn = {
  field: 'deviceTimestamp',
  text: 'Event:deviceTimestamp',
  formatter: (x: EventIndexSceneQuery['events']['list'][0]) => {
    return moment(x.deviceTimestamp * 1000).format(dateFormat);
  },
};

export const eventColumn = {
  id,
  log,
  deviceTime,
};
