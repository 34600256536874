import { TableColumn } from '../releox-engine/react/generic-table/TableColumn';

const name: TableColumn = {
  field: 'name',
  text: 'Department:name',
};

const id: TableColumn = {
  field: 'id',
  text: 'Department:id',
};

export const departmentColumn = {
  name,
  id,
};
