import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PaginationItem } from '../../hooks/pagination/get-pagination';
import { ReleoxPagination } from '../../hooks/pagination/use-releox-pagination';
import { mergeClasses } from '../../utils/merge-classes/merge-classes';
import { appIndexScenePaginationConfig } from './app-index-scene-pagination-config';

type AppIndexPaginationProps = {
  paginationItem: PaginationItem;
};

const Pagination = ({ paginationItem }: AppIndexPaginationProps) => {
  const activeClasses = 'z-10 bg-primary text-white hover:bg-primary-500';

  const defaultClasses =
    'bg-white border-primary text-primary-500 hover:text-white hover:bg-primary-500 hover:border-primary-500';

  const extraClass: string[] = [];

  let text: string | number | JSX.Element = paginationItem.text;

  if (paginationItem.type === 'first')
    text = <appIndexScenePaginationConfig.LeftIcon className="h-5 w-5" aria-hidden="true" />;

  if (paginationItem.type === 'last')
    text = <appIndexScenePaginationConfig.RightIcon className="h-5 w-5" aria-hidden="true" />;

  if (paginationItem.isFirst) extraClass.push(' rounded-l-md');

  if (paginationItem.isLast) extraClass.push(' rounded-r-md');

  return (
    <button
      onClick={() => paginationItem.onClick()}
      className={mergeClasses(
        paginationItem.isCurrent ? activeClasses : defaultClasses,
        'relative inline-flex items-center px-4 py-2 border text-sm font-medium',
        extraClass.join(' ')
      )}
    >
      {text}
    </button>
  );
};

type Props = {
  pagination: ReleoxPagination;
};

export const AppIndexScenePagination = ({ pagination }: Props) => {
  const { t } = useTranslation('Pagination');

  return (
    <div className="flex items-center justify-between border-t border-gray-200 pt-4">
      <div className="flex-1 flex justify-between sm:hidden">
        {pagination.currentPageIndex > 0 ? (
          <Link
            to="#"
            onClick={() => pagination.previousPage()}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            {t('previous')}
          </Link>
        ) : (
          <span />
        )}
        {pagination.pageCount && pagination.currentPageIndex !== pagination.pageCount - 1 ? (
          <Link
            to="#"
            onClick={() => pagination.nextPage()}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            {t('next')}
          </Link>
        ) : null}
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            {t('page')} <span className="font-medium">{pagination.currentPage}</span>. {t('result')}{' '}
            {pagination.skip + 1} - {pagination.skip + pagination.perPage} / {pagination.total}
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            {pagination.pagination.map((paginationItem) => (
              <Pagination key={paginationItem.text} paginationItem={paginationItem} />
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
