/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../components/Page';
import { useIndex, UseIndexOptions } from '../../hooks/use-index/use-index';
import { getSortableTableColumns } from '../../hooks/use-sortable-columns/use-sortable-columns';
import { mergeClasses } from '../../utils/merge-classes/merge-classes';
import { GenericTable, GenericTableProps } from '../generic-table/GenericTable';
import { appIndexSceneConfig, AppIndexSceneTableClasses } from './app-index-scene-config';
import { AppIndexScenePagination } from './AppIndexScenePagination';

type Query = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  loading: boolean;
};

type Prop = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lazyQuery: [(options: any) => void, Query];
  createUrl?: string;
  dataPath: string;
  totalCountPath: string;
  tNS?: string;
  Tool?: JSX.Element;
  Tags?: JSX.Element | JSX.Element[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  where?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trOnClick?: (data: any) => any;
  currentPageIndex?: number;
  onCurrentPageIndexChange?: (page: number) => void;
  tableClasses?: AppIndexSceneTableClasses;
  defaultOrderBy?: UseIndexOptions['defaultOrderBy'];
};

export const AppIndexScene = (props: Omit<GenericTableProps, 'data'> & Prop) => {
  const { t } = useTranslation(props.tNS);

  const { pagination, orderBy, setOrderBy } = useIndex({
    perPage: appIndexSceneConfig.perPage,
    defaultOrderBy: props.defaultOrderBy || appIndexSceneConfig.defaultOrderBy,
    totalCount: _.get(props.lazyQuery[1].data, props.totalCountPath),
    fetch: props.lazyQuery[0],
    where: props.where,
    onCurrentPageIndexChange: props.onCurrentPageIndexChange,
    currentPageIndex: props.currentPageIndex,
  });

  // Get sortable columns and use memo to save result
  const sortableColumns = useMemo(
    () => getSortableTableColumns(props.columns, orderBy),
    [props.columns, orderBy]
  );

  if (!sortableColumns) throw new Error('Missing columns');

  const data = useMemo(
    () => _.get(props.lazyQuery[1].data, props.dataPath),
    [props.lazyQuery, props.dataPath]
  );

  return (
    <Page title={t('title')}>
      <appIndexSceneConfig.Layout>
        <>{props.Tool}</>
        <appIndexSceneConfig.Title>{t('title')}</appIndexSceneConfig.Title>
        <>{props.Tags}</>
        <div className="overflow-x-auto">
          <GenericTable
            trOnClick={props.trOnClick}
            columns={sortableColumns}
            onSortClick={setOrderBy}
            data={data}
            sortIconClassNames={{
              asc: ({ onClick }) => (
                <appIndexSceneConfig.SortAscendingIcon
                  height={16}
                  className="inline-block ml-1 cursor-pointer"
                  onClick={onClick}
                />
              ),
              desc: ({ onClick }) => (
                <appIndexSceneConfig.SortDescendingIcon
                  height={16}
                  className="inline-block ml-1 cursor-pointer"
                  onClick={onClick}
                />
              ),
            }}
            thActiveClassNames={mergeClasses(
              appIndexSceneConfig?.defaultClasses?.thActiveClassNames,
              props.tableClasses?.thActiveClassNames
            )}
            tableClassNames={mergeClasses(
              appIndexSceneConfig?.defaultClasses?.tableClassNames,
              props.tableClasses?.tableClassNames
            )}
            tHeadClassNames={mergeClasses(
              appIndexSceneConfig?.defaultClasses?.tHeadClassNames,
              props.tableClasses?.tHeadClassNames
            )}
            thClassNames={mergeClasses(
              appIndexSceneConfig?.defaultClasses?.thClassNames,
              props.tableClasses?.thClassNames
            )}
            tdClassNames={mergeClasses(
              appIndexSceneConfig?.defaultClasses?.tdClassNames,
              props.tableClasses?.tdClassNames
            )}
            trClassNames={mergeClasses(
              appIndexSceneConfig?.defaultClasses?.trClassNames,
              props.tableClasses?.trClassNames,
              props.trOnClick ? 'cursor-pointer hover:bg-primary-50' : undefined
            )}
          />
          {Array.isArray(data) && data.length === 0 && props.tNS ? (
            <appIndexSceneConfig.EmptyTableElement tNS={props.tNS} createUrl={props.createUrl} />
          ) : null}
          {!Array.isArray(data) && props.tNS ? (
            <appIndexSceneConfig.LoadingElement tNS={props.tNS} />
          ) : null}
        </div>
        <div className="pt-3">
          <AppIndexScenePagination pagination={pagination} />
        </div>
      </appIndexSceneConfig.Layout>
    </Page>
  );
};
