import { useEffect, useState } from 'react';

type OnChange<T> = (opt: T) => void;

export const useObjectChangeWatcher = <T>(watched: T, onChange: OnChange<T>) => {
  const [cache, setCache] = useState<string | null>(null);

  useEffect(() => {
    const localCache = JSON.stringify(watched);
    if (localCache !== cache) {
      setCache(localCache);
      onChange(watched);
    }
  }, [watched, cache, onChange]);
};
