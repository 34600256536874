import { useTranslation } from 'react-i18next';
import { DeviceUserIndexSceneQuery } from '../generated/graphql';
import { TableColumn } from '../releox-engine/react/generic-table/TableColumn';

const id: TableColumn = {
  field: 'id',
  text: 'DeviceUser:id',
};

const name: TableColumn = {
  field: 'name',
  text: 'DeviceUser:name',
};

const tag: TableColumn = {
  field: 'tag',
  text: 'DeviceUser:tag',
};

const accountType: TableColumn = {
  field: 'accountType',
  text: 'DeviceUser:accountType',
  formatter: (d: DeviceUserIndexSceneQuery['deviceUsers']['list'][0]) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation('DeviceUser');
    return t(d.accountType);
  },
};

export const deviceUserColumn = {
  id,
  name,
  tag,
  accountType,
};
