import { deviceUserFilterInput } from '../inputs/device-user-filter.input';
import { deviceUserInput } from '../inputs/device-user.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [
  deviceUserInput.firstName,
  deviceUserInput.lastName,
  deviceUserInput.tag,
  deviceUserFilterInput.accountType,
];

export const deviceUserFilterForm = createForm(form);

export type DeviceUserFilterFormBody = {
  firstName: '';
  lastName: '';
  tag: '';
  accountType: '';
};
