import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CardTitle } from '../../components/CardTitle';
import { Loading } from '../../components/Loading';
import { getRoute } from '../../config/routes.config';
import { Yup } from '../../config/yup.instance';
import {
  useDeviceUserDepartmentEditSceneQuery,
  useUpdateDeviceUserDepartmentsMutation,
} from '../../generated/graphql';
import { useId } from '../../hooks/use-id';
import { createForm } from '../../releox-engine/form/FormGenerator';
import { FormInput } from '../../releox-engine/form/FormInput';
import { AppFormGenerator } from '../../releox-engine/react/app-form-generator/AppFormGenerator';
import { BackButtonLink } from '../../releox-engine/react/buttons/LinkButton';
import { SubmitButton } from '../../releox-engine/react/buttons/SubmitButton';

type F = {
  [key: string]: boolean;
};

export const DeviceUserDepartmentEditScene = (): JSX.Element => {
  const { t } = useTranslation('DeviceUserDepartmentEditScene');
  const id = useId();

  const { data, loading: queryLoading } = useDeviceUserDepartmentEditSceneQuery({
    variables: { id },
  });

  const [updateDepartments, { loading }] = useUpdateDeviceUserDepartmentsMutation({
    onCompleted: (d) => {
      if (d.updateDeviceUserDepartments) {
        toast.success(t('Common:saved'));
      }
    },
  });

  const handleSubmit = useCallback(
    (body: F) => {
      const departments: number[] = [];

      const keys = Object.keys(body);
      let key: string;

      for (let i = 0; i < keys.length; i += 1) {
        key = keys[i];
        if (body[key]) {
          departments.push(parseInt(key, 10));
        }
      }

      updateDepartments({ variables: { id, body: { departments } } });
    },
    [id, updateDepartments]
  );

  const Footer = (
    <div>
      <SubmitButton loading={loading} />
      <BackButtonLink to={getRoute('DEVICE_USER_INDEX')} />
    </div>
  );

  const form = useMemo(() => {
    if (data?.departments) {
      const existingDepartments = data.deviceUser.departments.map((department) => department.id);
      const inputs: FormInput[] = data.departments.list.map(
        (department): FormInput => ({
          type: 'checkbox',
          initValue: existingDepartments.includes(department.id),
          validation: Yup.boolean(),
          props: {
            name: `${department.id}`,
            label: department.name,
          },
        })
      );
      return createForm(inputs);
    }
  }, [data]);

  if (!form || queryLoading) return <Loading />;

  return (
    <>
      <CardTitle>{t('title')}</CardTitle>
      <AppFormGenerator
        FooterElement={Footer}
        wrapperClassNames="space-y-4"
        form={form}
        onSubmit={handleSubmit}
      />
    </>
  );
};
