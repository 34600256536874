import { deviceUserInput } from '../inputs/device-user.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [
  deviceUserInput.firstName,
  deviceUserInput.lastName,
  deviceUserInput.pin,
  deviceUserInput.pinConfirm,
  deviceUserInput.tag,
  deviceUserInput.accountType,
  deviceUserInput.identifier,
];

export const deviceUserForm = createForm(form);

export type DeviceUserFormBody = {
  firstName: string;
  lastName: string;
  pin: string;
  pinConfirm: string;
  tag: string;
  accountType: string;
  identifier: string;
};
