import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { deviceColumn } from '../../columns/device.column';
import { ButtonLink } from '../../components/ButtonLink';
import { getRoute } from '../../config/routes.config';
import { useDeviceIndexSceneLazyQuery } from '../../generated/graphql';
import { AppIndexScene } from '../../releox-engine/react/releox-app-index-scene/AppIndexScene';

export const DeviceIndexScene = (): JSX.Element => {
  const { t } = useTranslation('DeviceIndexScene');
  const query = useDeviceIndexSceneLazyQuery();
  const navigate = useNavigate();

  return (
    <AppIndexScene
      tNS="DeviceIndexScene"
      Tool={
        <ButtonLink to={getRoute('DEVICE_CREATE')} className="float-right">
          {t('create')}
        </ButtonLink>
      }
      tableClasses={{
        trClassNames: 'cursor-pointer',
      }}
      lazyQuery={query}
      columns={[
        deviceColumn.id,
        deviceColumn.name,
        deviceColumn.hardwareId,
        deviceColumn.heartbeat,
      ]}
      trOnClick={(data) => navigate(getRoute('DEVICE_EDIT', { id: data.id }))}
      dataPath="devices.list"
      totalCountPath="devices.totalCount"
    />
  );
};
