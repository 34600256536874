export const PaginationTranslation = {
  fi: {
    Pagination: {
      page: 'Sivu',
      result: 'Tulos',
    },
  },
  en: {
    Pagination: {
      page: 'Page',
      result: 'Result',
    },
  },
};
