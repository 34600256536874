import { OrderBy } from './use-index';

type AdapterParam = {
  orderBy: OrderBy;
  skip: number;
  take: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  where?: any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UseIndexDefaultAdapter = (args: AdapterParam) => any;

export const useIndexDefaultAdapter = ({ orderBy, skip, take, where }: AdapterParam) => ({
  variables: {
    orderBy: [orderBy],
    skip,
    take,
    where,
  },
});
