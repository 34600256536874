import { Card } from './Card';

type Props = {
  children: JSX.Element;
};

export const FormLayout = ({ children }: Props) => {
  return (
    <div className="mx-auto w-full max-w-3xl sm:px-2">
      <Card>{children}</Card>
    </div>
  );
};
