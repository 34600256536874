import * as Yup from 'yup';
import { FormInput } from '../releox-engine/form/FormInput';

const firstName: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'firstName',
    label: 'DeviceUser:firstName',
    type: 'text',
  },
};

const lastName: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'lastName',
    label: 'DeviceUser:lastName',
    type: 'text',
  },
};

const accountType: FormInput = {
  type: 'select',
  initValue: 'basic',
  validation: Yup.string().required(),
  props: {
    name: 'accountType',
    label: 'DeviceUser:accountType',
    options: [
      {
        value: 'basic',
        label: 'DeviceUser:basic',
      },
      {
        value: 'distributor',
        label: 'DeviceUser:distributor',
      },
    ],
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const digitsOnly = (value: any) => /^\d+$/.test(value);

const pin: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string()
    .test('Digits only', 'Validation:numberOnly', digitsOnly)
    .min(4)
    .max(4)
    .required(),
  props: {
    name: 'pin',
    label: 'DeviceUser:pin',
    type: 'password',
  },
};

const tag: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'tag',
    label: 'DeviceUser:tag',
    type: 'text',
  },
};

const pinConfirm: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string()
    .oneOf([Yup.ref('pin'), null], 'Validation:match')
    .required(),
  props: {
    name: 'pinConfirm',
    label: 'DeviceUser:pinConfirm',
    type: 'password',
  },
};

const identifier: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string(),
  props: {
    name: 'identifier',
    label: 'DeviceUser:identifier',
    type: 'text',
  },
};

export const deviceUserInput = {
  firstName,
  lastName,
  tag,
  pin,
  accountType,
  pinConfirm,
  identifier,
};
