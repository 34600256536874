import _ from 'lodash';
import React from 'react';
import { TableColumn } from './TableColumn';

interface Props {
  columns: TableColumn[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  tdClassNames?: string;
  trClassNames?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trOnClick?: (data: any) => any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDefault = (value: any) => {
  if ([null, undefined, ''].includes(value)) return '-';
  return value;
};

export const GenericTableRow = ({
  columns,
  data,
  tdClassNames,
  trClassNames,
  trOnClick,
}: Props): JSX.Element => {
  const invalidFields: string[] = [];

  columns.forEach((c) => {
    if (_.get(data, c.field) === undefined && !c.default) {
      invalidFields.push(c.field);
    }
  });

  if (invalidFields.length) throw new Error(`Table missing data: ${invalidFields.join(', ')}`);

  return (
    <tr
      key={data.id}
      className={trClassNames}
      id={data.id}
      onClick={() => (trOnClick ? trOnClick(data) : undefined)}
    >
      {columns.map((c) => {
        const classes = [tdClassNames];

        if (c.tdClassNames) classes.unshift(c.tdClassNames);

        return (
          <td key={c.field} className={classes.join(' ')}>
            {c.formatter ? c.formatter(data) : getDefault(_.get(data, c.field))}
          </td>
        );
      })}
    </tr>
  );
};
