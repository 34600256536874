import { LightBulbIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { AppIndexSceneEmptyElementProps } from '../releox-engine/react/releox-app-index-scene/AppIndexSceneEmptyElementProps';
import { ButtonLink } from './ButtonLink';

export const EmptyTableElement = ({ tNS, createUrl }: AppIndexSceneEmptyElementProps) => {
  const { t } = useTranslation(tNS);

  return (
    <div className="my-16 text-center">
      <LightBulbIcon height={30} className="mx-auto text-primary mb-2" />
      <p className="text-center text-gray-500 text-sm">{t('emptyText')}</p>
      {createUrl ? (
        <ButtonLink to={createUrl} className="mt-4 inline-block">
          {t('emptyButtonText')}
        </ButtonLink>
      ) : null}
    </div>
  );
};
