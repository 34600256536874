import { Form, Formik } from 'formik';
import _ from 'lodash';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeviceUserFilterContext,
  DeviceUserFilterContextData,
} from '../../contexts/DeviceUserFilterContext';
import {
  deviceUserFilterForm,
  DeviceUserFilterFormBody,
} from '../../forms/device-user-filter.form';
import { Button } from '../../releox-engine/react/buttons/Button';
import { Card } from '../Card';
import { CardTitle } from '../CardTitle';

export const DeviceUserFilter = () => {
  const { t } = useTranslation('DeviceUserIndexScene');
  const [filter, setFilter] = useContext(DeviceUserFilterContext);

  const initialValues = useMemo(() => {
    const formInit = _.clone(deviceUserFilterForm.meta.initValues);

    if (filter.tag) formInit.tag = filter.tag;
    if (filter.lastName) formInit.lastName = filter.lastName;
    if (filter.firstName) formInit.firstName = filter.firstName;
    if (filter.accountType) formInit.accountType = filter.accountType;

    return formInit;
  }, [filter]);

  const handleSubmit = useCallback(
    (body: DeviceUserFilterFormBody) => {
      const query: DeviceUserFilterContextData = {};

      if (body.tag) query.tag = body.tag;
      if (body.lastName) query.lastName = body.lastName;
      if (body.firstName) query.firstName = body.firstName;
      if (body.accountType) query.accountType = body.accountType;

      setFilter(query);
    },
    [setFilter]
  );

  // handle onReset
  const handleReset = useCallback(() => {
    setFilter({});
  }, [setFilter]);

  return (
    <Card>
      <CardTitle>{t('filter')}</CardTitle>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} onReset={handleReset}>
        <Form className="space-y-3">
          <deviceUserFilterForm.Element />
          <Button type="reset">{t('reset')}</Button>
          <Button type="submit" className="float-right">
            {t('submit')}
          </Button>
        </Form>
      </Formik>
    </Card>
  );
};
