import _ from 'lodash';
import { AcceptInvitationSceneTranslation } from '../scenes/auth/accept-invitation/AcceptInvitationScene.translation';
import { ForgotSceneTranslation } from '../scenes/auth/forgot/ForgotScene.translation';
import { ForgotSuccessSceneTranslation } from '../scenes/auth/forgot/ForgotSuccessScene.translation';
import { LoginSceneTranslation } from '../scenes/auth/login/LoginScene.translation';
import { ResetPasswordSceneTranslation } from '../scenes/auth/reset/ResetPasswordScene.translation';
import { ResetPasswordSuccessSceneTranslation } from '../scenes/auth/reset/ResetPasswordSuccessScene.translation';
import { DepartmentCreateSceneTranslation } from '../scenes/department/DepartmentCreateSceneScene.translation';
import { DepartmentEditSceneTranslation } from '../scenes/department/DepartmentEditScene.translation';
import { DepartmentIndexSceneTranslation } from '../scenes/department/DepartmentIndexScene.translation';
import { DeviceUserCreateSceneTranslation } from '../scenes/device-user/DeviceUserCreateScene.translation';
import { DeviceUserDepartmentEditSceneTranslation } from '../scenes/device-user/DeviceUserDepartmentEditScene.translation';
import { DeviceUserIndexSceneTranslation } from '../scenes/device-user/DeviceUserIndexScene.translation';
import { DeviceUserInfoEditSceneTranslation } from '../scenes/device-user/DeviceUserInfoEditScene.translation';
import { DeviceUserPinEditSceneTranslation } from '../scenes/device-user/DeviceUserPinEditScene.translation';
import { DeviceCreateSceneTranslation } from '../scenes/device/DeviceCreateScene.translation';
import { DeviceEditSceneTranslation } from '../scenes/device/DeviceEditScene.translation';
import { DeviceIndexSceneTranslation } from '../scenes/device/DeviceIndexScene.translation';
import { EventIndexSceneTranslation } from '../scenes/event/EventIndexScene.translation';
import { HomeSceneTranslation } from '../scenes/home/HomeScene.translation';
import { ChangePasswordSceneTranslation } from '../scenes/password/ChangePasswordScene.translation';
import { UserEditSceneTranslation } from '../scenes/user/UserEditScene.translation';
import { UserIndexSceneTranslation } from '../scenes/user/UserIndexScene.translation';
import { UserInviteSceneTranslation } from '../scenes/user/UserInviteScene.translation';
import { AuthErrorTranslation } from './authError.translation';
import { commonTranslation } from './common.translation';
import { departmentTranslation } from './department.translation';
import { deviceUserTranslation } from './device-user.translation';
import { deviceTranslation } from './device.translation';
import { DeviceUserEditRouterTranslation } from './DeviceUserEditRouter.translation';
import { ErrorMessageTranslation } from './error-message.translation';
import { EventTranslation } from './event.translation';
import { EventFilterTranslation } from './eventFilter.translation';
import { PaginationTranslation } from './pagination.translation';
import { ReactSelectInputTranslation } from './reactSelectInput.translation';
import { sidebarTranslation } from './sidebar.translation';
import { userTranslation } from './user.translation';
import { ValidationTranslation } from './validation.translation';

export const translation = _.merge(
  LoginSceneTranslation,
  ForgotSceneTranslation,
  DepartmentEditSceneTranslation,
  DeviceUserDepartmentEditSceneTranslation,
  DeviceUserInfoEditSceneTranslation,
  ValidationTranslation,
  DeviceUserPinEditSceneTranslation,
  DeviceEditSceneTranslation,
  DeviceUserEditRouterTranslation,
  ChangePasswordSceneTranslation,
  DepartmentCreateSceneTranslation,
  sidebarTranslation,
  UserEditSceneTranslation,
  UserInviteSceneTranslation,
  DeviceIndexSceneTranslation,
  UserIndexSceneTranslation,
  DeviceCreateSceneTranslation,
  ResetPasswordSceneTranslation,
  DepartmentIndexSceneTranslation,
  AcceptInvitationSceneTranslation,
  ForgotSuccessSceneTranslation,
  ResetPasswordSuccessSceneTranslation,
  commonTranslation,
  ErrorMessageTranslation,
  departmentTranslation,
  DeviceUserIndexSceneTranslation,
  userTranslation,
  DeviceUserCreateSceneTranslation,
  deviceUserTranslation,
  deviceTranslation,
  PaginationTranslation,
  EventTranslation,
  EventIndexSceneTranslation,
  EventFilterTranslation,
  ReactSelectInputTranslation,
  AuthErrorTranslation,
  HomeSceneTranslation
);
