import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Card } from '../components/Card';
import { getRoute } from '../config/routes.config';
import { useDeviceUserEditRouterQueryQuery } from '../generated/graphql';
import { useId } from '../hooks/use-id';
import { DeviceUserDepartmentEditScene } from '../scenes/device-user/DeviceUserDepartmentEditScene';
import { DeviceUserInfoEditScene } from '../scenes/device-user/DeviceUserInfoEditScene';
import { DeviceUserPinEditScene } from '../scenes/device-user/DeviceUserPinEditScene';
import { joinClassNames } from '../utils/join-class-names';

type Menu = {
  name: string;
  href: string;
  current: boolean;
};

const scene = 'DEVICE_USER_EDIT';

export const DeviceUserEditRouter = () => {
  const { t } = useTranslation('DeviceUserEditRouter');
  const id = useId();
  const { data } = useDeviceUserEditRouterQueryQuery({ variables: { id } });
  const { page } = useParams<{ page: string }>();

  const navigation = useMemo(
    (): (Menu | null)[] => [
      {
        name: t('info'),
        href: getRoute(scene, { id, page: 'info' }),
        current: page === 'info',
      },
      {
        name: t('pin'),
        href: getRoute(scene, { id, page: 'pin' }),
        current: page === 'pin',
      },
      data?.deviceUser.accountType === 'basic'
        ? {
            name: t('department'),
            href: getRoute(scene, { id, page: 'department' }),
            current: page === 'department',
          }
        : null,
    ],
    [id, page, t, data]
  );

  let Page: JSX.Element | null = null;

  if (page === 'info') {
    Page = <DeviceUserInfoEditScene />;
  }
  if (page === 'pin') {
    Page = <DeviceUserPinEditScene />;
  }
  if (page === 'department') {
    Page = <DeviceUserDepartmentEditScene />;
  }

  return (
    <div className="mx-auto w-full max-w-3xl sm:px-2">
      <Card>
        <div className="grid grid-cols-6 gap-4">
          <nav className="space-y-1 col-span-2" aria-label="Sidebar">
            {navigation.map((item) => {
              if (!item) return null;
              return (
                <Link
                  key={item.name}
                  to={item.href}
                  className={joinClassNames(
                    item.current
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'flex items-center px-3 py-2 text-sm font-medium rounded-md'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  <span className="truncate">{item.name}</span>
                </Link>
              );
            })}
          </nav>
          <div className="col-span-4">{Page}</div>
        </div>
      </Card>
    </div>
  );
};
