import { departmentInput } from '../inputs/department.input';
import { createForm } from '../releox-engine/form/FormGenerator';
import { FormInput } from '../releox-engine/form/FormInput';

const form: FormInput[] = [
  departmentInput.name,
];

export const departmentForm = createForm(form);

export type DepartmentFormBody = {
  name: string;
};
