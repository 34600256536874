/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import { useTranslation } from 'react-i18next';
import { SortableTableColumn } from '../../hooks/use-sortable-columns/use-sortable-columns';
import { TableColumn } from './TableColumn';

interface Props {
  column: SortableTableColumn;
  onSortClick?(column: TableColumn): void;
  thClassNames?: string;
  thActiveClassNames?: string;
  sortIconClassNames?: GenericTableHeaderSortIcon;
}

type GenericTableHeaderSortIconProps = {
  onClick(): void;
};

export type GenericTableHeaderSortIcon = {
  asc: (props: GenericTableHeaderSortIconProps) => JSX.Element;
  desc: (props: GenericTableHeaderSortIconProps) => JSX.Element;
};

export const GenericTableHeader = ({
  column,
  onSortClick,
  thClassNames,
  thActiveClassNames,
  sortIconClassNames,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  let icon;
  if (column.sort && sortIconClassNames && onSortClick) {
    icon = <sortIconClassNames.asc onClick={() => onSortClick(column)} />;
    if (column.activeSort === 'desc') {
      icon = <sortIconClassNames.desc onClick={() => onSortClick(column)} />;
    }
  }

  return (
    <th
      key={column.field}
      className={[thClassNames, column.activeSort ? thActiveClassNames : undefined]
        .filter((x) => x)
        .join(' ')}
    >
      {t(column.text)}
      {icon}
    </th>
  );
};
