import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { userColumn } from '../../columns/user.column';
import { ButtonLink } from '../../components/ButtonLink';
import { UserFilter } from '../../components/filters/UserFilter';
import { getRoute } from '../../config/routes.config';
import { UserFilterContext } from '../../contexts/UserFilterContext';
import { useUserIndexSceneLazyQuery } from '../../generated/graphql';
import { AppIndexScene } from '../../releox-engine/react/releox-app-index-scene/AppIndexScene';

export const UserIndexScene = (): JSX.Element => {
  const { t } = useTranslation('UserIndexScene');
  const query = useUserIndexSceneLazyQuery();
  const [filter] = useContext(UserFilterContext);
  const navigate = useNavigate();

  const Tool = (
    <ButtonLink className="float-right" to={getRoute('USER_INVITE')}>
      {t('create')}
    </ButtonLink>
  );

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-x-3 gap-y-3">
      <div>
        <UserFilter />
      </div>
      <div className="col-span-3">
        <AppIndexScene
          tNS="UserIndexScene"
          lazyQuery={query}
          trOnClick={(data) => navigate(getRoute('USER_EDIT', { id: data.id }))}
          columns={[userColumn.name, userColumn.email]}
          dataPath="users.list"
          totalCountPath="users.totalCount"
          Tool={Tool}
          where={filter}
        />
      </div>
    </div>
  );
};
