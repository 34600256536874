export const deviceUserTranslation = {
  fi: {
    DeviceUser: {
      email: 'Sähköposti',
      password: 'Salasana',
      id: '#',
      name: 'Nimi',
      lastName: 'Sukunimi',
      firstName: 'Etunimi',
      pin: 'Pin',
      pinConfirm: 'Vahvista pin',
      tag: 'Tagin UID-tunnus',
      identifier: 'Henkilönumero',
      accountType: 'Käyttäjätyyppi',
      basic: 'Perus',
      distributor: 'Jakelija',
      chooseAccountType: 'Valitse',
    },
  },
};
