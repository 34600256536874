import { CSSProperties, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { joinClassNames } from '../../../utils/join-class-names';

type Type = 'submit' | 'reset' | 'button';

interface Props {
  type: Type;
  children: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  loading?: boolean;
  className?: string;
  size?: 'sm' | 'xs';
  style?: CSSProperties;
}

export const buttonClassNames = '';

export const Button = (props: Props) => {
  const { t } = useTranslation('Common');
  const { type, children, loading, className, onClick, style } = props;

  let text = children;

  if (loading) text = t('loading');

  const size = `text-${props.size || 'sm'}`;

  let padding = 'px-3 py-2';

  if (props.size === 'xs') {
    padding = 'px-2.5 py-1.5';
  }

  const disabled = 'disabled:bg-gray-300 disabled:text-white';
  const hover = 'hover:bg-primary-500';

  return (
    <button
      type={type}
      style={style}
      onClick={onClick}
      className={joinClassNames(
        `w-sm shadow-sm font-md text-white bg-primary`,
        size,
        disabled,
        hover,
        padding,
        className
      )}
      disabled={loading}
    >
      {text}
    </button>
  );
};
