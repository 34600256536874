import { TableColumn } from '../releox-engine/react/generic-table/TableColumn';

const name: TableColumn = {
  field: 'name',
  text: 'User:name',
};

const email: TableColumn = {
  field: 'email',
  text: 'User:email',
};

export const userColumn = {
  name,
  email,
};
