import { List } from 'immutable';
import { TableColumn } from '../../react/generic-table/TableColumn';
import { OrderBy, OrderBySort } from '../use-index/use-index';

export type SortableTableColumn = TableColumn & {
  activeSort?: OrderBySort;
};

export const getSortableTableColumns = (columns: TableColumn[], orderBy: OrderBy) => {
  // Create immutable array
  let localColumns = List<SortableTableColumn>(columns || []);

  // Find current orderBy column
  const columnIndex = localColumns.findIndex((c) => c?.field === Object.keys(orderBy)[0]);

  // Get current sort order (asc or desc)
  const currentSortOrder = Object.values(orderBy)[0];

  // If orderBy column is found
  if (columnIndex !== -1) {
    const column = localColumns.get(columnIndex);

    // This line is for fixing typescript auto typing undefined type
    if (!column) throw new Error('[UseIndex] Column is undefined');

    // Set activeSort field to current sorted column
    localColumns = localColumns.set(columnIndex, {
      ...column,
      activeSort: currentSortOrder,
    });
  }

  return localColumns.toArray();
};
