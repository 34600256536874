export const commonTranslation = {
  fi: {
    Common: {
      loading: 'Lataa..',
      save: 'Tallenna',
      back: 'Takaisin',
      saved: 'Tallennettu',
      deleted: 'Poistettu',
      deleteText: 'Poista',
    },
  },
};
