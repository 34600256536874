import { useTranslation } from 'react-i18next';

const LoadingAnimation = () => (
  <div className="flex items-center justify-center mb-3">
    <div className="w-8 h-8 border-b-2 border-primary rounded-full animate-spin"></div>
  </div>
);

export const LoadingTableElement = () => {
  const { t } = useTranslation('Common');

  return (
    <div className="my-16 text-center">
      <LoadingAnimation />
      <p className="text-center text-gray-500">{t('loading')}</p>
    </div>
  );
};
