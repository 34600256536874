import axios from 'axios';
import _ from 'lodash';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { eventColumn } from '../../columns/event.column';
import { EventFilter } from '../../components/filters/EventFilter';
import { EventFilterContext } from '../../contexts/EventFilterContext';
import { useEventIndexSceneLazyQuery } from '../../generated/graphql';
import { Button } from '../../releox-engine/react/buttons/Button';
import { AppIndexScene } from '../../releox-engine/react/releox-app-index-scene/AppIndexScene';
import { saveStringAsFile } from '../../utils/save-string-as-file';

export const EventIndexScene = (): JSX.Element => {
  const { t } = useTranslation('EventIndexScene');
  const query = useEventIndexSceneLazyQuery();
  const [filter] = useContext(EventFilterContext);

  const handleExport = useCallback(async () => {
    const token = localStorage.getItem('token');
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/export-system`, {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      });

      saveStringAsFile('data.csv', data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      toast.error(_.get(e, 'response.data.message'));
    }
  }, []);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-x-3 gap-y-3">
      <div>
        <EventFilter />
      </div>
      <div className="col-span-3">
        <AppIndexScene
          tNS="EventIndexScene"
          lazyQuery={query}
          columns={[eventColumn.id, eventColumn.deviceTime, eventColumn.log]}
          dataPath="events.list"
          totalCountPath="events.totalCount"
          where={filter}
          Tool={
            <div className="float-right">
              {/* Button */}
              <Button type="button" onClick={handleExport}>
                {t('export')}
              </Button>
            </div>
          }
        />
      </div>
    </div>
  );
};
