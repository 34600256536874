import moment from 'moment';
import { dateFormat } from '../config/date-format';
import { TableColumn } from '../releox-engine/react/generic-table/TableColumn';

const id: TableColumn = {
  text: 'Device:id',
  field: 'id',
};

const hardwareId: TableColumn = {
  text: 'Device:hardwareId',
  field: 'hardwareId',
};

const name: TableColumn = {
  text: 'Device:name',
  field: 'name',
};

// Yhteysaika
const heartbeat: TableColumn = {
  text: 'Device:heartbeat',
  field: 'heartbeat',
  formatter: (data) => {
    if (!data.heartbeat) return '-';
    return moment(data.heartbeat).format(dateFormat);
  },
};

export const deviceColumn = {
  id,
  heartbeat,
  hardwareId,
  name,
};
